/* global google */
import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, InfoWindow } from '@react-google-maps/api';
import { googleMapsApiKey } from '../../../config/backendServer.config';
import CustomMarker from '../../../components/Markers.component';
import { iconsList } from '../../../config/status.config';
import {
  getDeliveryManDispatchedById,
  socketConnectPertners,
} from '../services/server.api.service';
import { useSelector } from 'react-redux';
// selector
import { getPartnerDeliveriesDataState } from '../redux/selectors';
import { getlocationsHistoryForDeliverManApi } from '../../Provider/service/serverAPI.service';
import { formatTime } from '../../../helpers/index';
import { makeStyles } from '@material-ui/core/styles';

const rows = [];
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  filter: {
    padding: 10,
  },
  text: {
    textAlign: 'center',
  },
});

export default function DisplayOrderInMapForMonitoring({ data }) {
  const [delivery, setDelivery] = useState({});
  const [deliveryManPathFromDB, setDeliveryManPathFromDB] = useState({});
  const [zoom, setZoom] = useState(13);
  const [socketDeliveryMan, setSocketDeliveryMan] = useState([]);
  const [path, setPath] = useState({});
  const [client, setClient] = useState([]);
  const [store, setStore] = useState([]);

  useEffect(() => {
    setPath({
      lat: parseFloat(data?.location[0]),
      lng: parseFloat(data?.location[1]),
    });
    setStore(
      (data?.orders || []).map((x) => ({
        lat: parseFloat(x?.pickupAddress?.latitude?.toString() || '0'),
        lng: parseFloat(x?.pickupAddress?.longitude?.toString() || '0'),
      })),
    );
    setClient(
      (data?.orders || []).map((x) => ({
        lat: parseFloat(x?.destinationAddress?.latitude?.toString() || '0'),
        lng: parseFloat(x?.destinationAddress?.longitude?.toString() || '0'),
      })),
    );
    return () => {};
  }, [data]);

  return (
    <div>
      <LoadScript
        id="script-loader"
        googleMapsApiKey={googleMapsApiKey}
        language="en"
        region="ma"
      >
        <GoogleMap
          mapContainerClassName="map"
          center={path && path}
          zoom={zoom}
          version="weekly"
          on
          options={{
            styles: [
              {
                featureType: 'icon',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
              },
            ],
          }}
        >
          {path && (
            <CustomMarker
              icon={iconsList.deliveryManWithOrder}
              // onClick={() => displayDetailstore(order.store._id)}
              size={40}
              // key={delivery.pickupAddress.idMagasin}
              position={path}
            />
          )}
          {client &&
            client.length > 0 &&
            client.map((x) => (
              <CustomMarker
                icon={iconsList.client}
                // onClick={() => displayDetailstore(order.store._id)}
                size={40}
                // key={delivery.pickupAddress.idMagasin}
                position={x}
              />
            ))}
          {store &&
            store.length > 0 &&
            store.map((x) => (
              <CustomMarker
                icon={iconsList.store}
                // onClick={() => displayDetailstore(order.store._id)}
                size={40}
                // key={delivery.pickupAddress.idMagasin}
                position={x}
              />
            ))}
        </GoogleMap>
      </LoadScript>
    </div>
  );
}
