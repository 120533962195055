/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import NotesIcon from '@material-ui/icons/Notes';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import SportsMotorsportsIcon from '@material-ui/icons/SportsMotorsports';
import GroupIcon from '@material-ui/icons/Group';
import StoreIcon from '@material-ui/icons/Store';
import SettingsIcon from '@material-ui/icons/Settings';
import AuditIcon from '@material-ui/icons/TrackChanges';
import QualityIcon from '@material-ui/icons/AddBox';
// core components/views for Admin layout
import MapViewCurrentOrdersPage from './features/dashboard/pages/MapViewCurrentOrders.page';
import MapStoresPage from './features/dashboard/pages/MapViewStores.page';
// import deliveryManMapStoresPage from './features/dashboard/pages/Map.delivery.page';
import MapViewDeliveryManPage from './features/dashboard/pages/MapViewDeliveryMan.page';
import MapViewOrdersPage from './features/dashboard/pages/MapViewOrders.page';
import ServiceCategoriesPage from './features/delivery-info/pages/ServiceCategories.page';
import ServicesPage from './features/delivery-info/pages/Services.page';
import CountriesPage from './features/delivery-info/pages/Countries.page';
import CitiesPage from './features/delivery-info/pages/Cities.page';
import DeliveryPricesPage from './features/delivery-info/pages/DelivieryPrices.page';
import PublicityPage from './features/delivery-info/pages/Publicity.page';
import CreatePublicity from './features/delivery-info/components/CreatePublicity.component';
import DispatchingModesPage from './features/delivery-info/pages/DispatchingModes.page';
import CityServiceMappingPage from './features/delivery-info/pages/CityServiceMapping.page';
import OrdersPage from './features/orders/pages/Orders.page';
import CurrentOrderPage from './features/orders/pages/CurrentOrder.page';
import CurrentOrderPage2 from './features/orders/pages/current.orders.v2';
import OrderHistoryPage from './features/orders/pages/OrderHistory.page';
import OrderRatingPage from './features/orders/pages/order.rating.page';
import PaymentPage from './features/payments/pages/Payments.page';
import NapsPaymentPage from './features/payments/pages/Naps.payment.page';
import CardsPage from './features/payments/pages/Cards.page';
import DeliveryManPage from './features/delivery-man/pages/DeliveryMan.page';
import CustomersPage from './features/customers/pages/Customers.page';
import UnapprovedCustomersPage from './features/customers/pages/UnapprouvedCustomers.page';
import kaalixPayHistoryPage from './features/customers/pages/kaalixPayHistory.page';
import kaalixUpHistoryPage from './features/customers/pages/kaalixUpHistory.page';
import CodePromoPage from './features/customers/pages/Code.promo.page';
import CustomerPaymentPage from './features/customers/pages/Customers.payment.page';
import StoresPage from './features/stores/pages/Stores.page';
import UnapprovedStoresPage from './features/stores/pages/UnapprovedStores.page';
import StoreMappingPage from './features/stores/pages/StoreMapping.page';
import ProductsPage from './features/dashboardStore/pages/Products.page';
import CategoryList from './features/dashboardStore/pages/CategoryList';
import Category from './features/dashboardStore/pages/Category';
import Specifications from './features/dashboardStore/pages/Specification';
import Item from './features/dashboardStore/pages/Item';
import Promo from './features/dashboardStore/pages/Promo';
import ApprouvedProvider from './features/Provider/pages/ApprouvedProvider';
import UnapprouvedProvider from './features/Provider/pages/UnapprouvedProvider';
import AddProvider from './features/Provider/pages/AddProvider';
import CreateTagPage from './features/delivery-info/pages/CreateTag.page';
import TagsPage from './features/delivery-info/pages/Tags.page';
import FlagsPage from './features/delivery-info/pages/flag.page';
import SharePage from './features/delivery-info/pages/Share.page';
import AdminPage from './features/setting/page/admin.page';
import ContactPage from './features/setting/page/contact.page';
import BalancePage from './features/Provider/pages/balance.page';
import ProviderBalance from './features/Provider/pages/ProviderBalance';
import CommissionHistoryPage from './features/Provider/pages/Commission.history.page';
import AppSettingPage from './features/setting/page/AppSetting.page';
import kaalixUpConfig from './features/setting/page/kaalixUpConfig.page';
import VirementProvider from './features/Provider/pages/VirementProvider';
import CommissionConfigPage from './features/delivery-info/pages/CommissionConfig.page';
import ChoubikCommissionConfig from './features/delivery-info/pages/ChoubikCommissionConfig.page';
import WorkingCalendarConfig from './features/Provider/pages/WorkingCalendarConfig.page';
import ProviderDispatchingPage from './features/Provider/pages/ProviderDispatching.page';
import ValidationTips from './features/Provider/pages/validationTips';
import OrderAlarmsPage from "./features/orders/pages/orderAlarms.page";
import StoreConfigPage from "./features/Accompagnement/pages/store.config.page";
import OrganisationPage from "./features/Partners/pages/Organisation.page";
import DeliveryMenPartnerPage from "./features/Partners/pages/DeliveryMen.partner.page";
import AllDeliveryMenPartnerPage from "./features/Partners/pages/AllDeliveryMen.partner.page";
import ServicesDisponibilityPage from "./features/Partners/pages/Services.disponibility.page";
import CallApiPage from "./features/Partners/pages/Call.api.page";
import CallApiForLoginPage from "./features/Partners/pages/Call.api.pageForLogin";
import CurrentPartnerOrdersPage from "./features/PartnersOrders/pages/Current.orders.page";
import CurrentPartnerOrdersV2Page from "./features/PartnersOrders/pages/Current.ordersV2.page";
import CurrentPartnerOrdersV3Page from "./features/PartnersOrders/pages/Current.ordersV3.page";
import TrackingDeliveryManPage from "./features/PartnersOrders/pages/Tracking.deliveryMan.page";
import OrderHistoryPartnerPage from "./features/PartnersOrders/pages/Order.history.page";
import OrderHistoryPartnerTerminated from "./features/PartnersOrders/pages/Deliveries.history.terminated";
import OrderHistoryPartnerPending from "./features/PartnersOrders/pages/Deliveries.history.pending";
import DeliveriesAlarmsPage from "./features/PartnersOrders/pages/DeliveriesAlarms.page";
import AuditPage from './features/audit/pages/Audit.page';
import NonQualityPage from 'features/non-quality/pages/Non-quality.page';
import NonQualityCustomerPage from 'features/non-quality/pages/Non-quality-customer.page';
import NonQualityDeliveryPage from 'features/non-quality/pages/Non-quality-delivery.page';
import ProviderLocation from "./features/Provider/pages/provider_location";
import deliveryManLocationTabPage from "./features/Provider/pages/deliveryManLocationTab.page"
import deliveryManLocationStatus from "./features/Provider/pages/deliveryManLocationStatus.page";
import ExportExelPage from "./features/orders/components/ExelHistory.component";
import OrganisationPageForAllParners from "./features/AllPatners/pages/Organisation.page";
import AllDeliveryMenPartnerPageForAllParners from "./features/AllPatners/pages/AllDeliveryMen.partner.page";
import DeliveryMenPartnerPageForAllParners from "./features/AllPatners/pages/DeliveryMen.partner.page";
import CurrentAllPartnersOrdersPage from "./features/AllPartnersOrders/pages/Current.orders.page";
import OrderHistoryAllPartnersPage from "./features/AllPartnersOrders/pages/Order.history.page";
import ResendDeliveredPage from "./features/AllPartnersOrders/pages/Resend.delivered.page";
import DeliveriesAlarmsAllPartnersPage from "./features/AllPartnersOrders/pages/DeliveriesAlarms.page";
import WorkingCalendarConfigPartner from "./features/Provider/pages/WorkingCalendarConfigPartner.page";
import CategoriesAppUserPage from './features/delivery-info/pages/CategoriesInAppUser.page';
import ServiceStore from './features/delivery-info/pages/ServiceListStore';
import Drivers from './features/driver/pages/ProviderDispatching.page';
import WorkingCalendarConfigDriver from './features/driver/pages/WorkingCalendarConfigDriver.page';
import currentRidePage from './features/driver/pages/current.ridepage';
import invalidRidesPage from './features/driver/pages/invalid.ride.page';
import RideHistoryPage from './features/driver/pages/RideHistory.page';
import DriverConfigPage from './features/driver/pages/DriverConfig.page';
// import NotCommitedPage from './features/notCommited/page/Not.commited.page';
import LivryOrganisationPage from './features/livry/pages/Organisation.page';

// ecomPartner 
import OrganisationEcomPage from "./features/ecom-Partners/pages/Organisation.page";
import DeliveryMenEcomPartnerPage from "./features/ecom-Partners/pages/DeliveryMen.partner.page";
import AllDeliveryMenEcomPartnerPage from "./features/ecom-Partners/pages/AllDeliveryMen.partner.page";
import CurrentDeliveriesEcomPartnerPage from "./features/EcomPartnersOrders/pages/Current.ecom.orders.page";
import CompletedDeliveriesEcomPartnerPage from "./features/EcomPartnersOrders/pages/Order.history.page";




// core components/views for RTL layout
const path = window.location.pathname;

export const dashStore = [
  {
    collapse: true,
    name: 'Product',
    icon: StoreIcon,
    state: 'productCollapse',
    views: [
      {
        path: '/store',
        name: 'Store',
        mini: 'ST',
        component: ProductsPage,
        layout: '/dashboard-store',
      },
      {
        path: '/categories-list',
        name: 'categories list (Product category list)',
        mini: 'CL',
        component: CategoryList,
        layout: '/dashboard-store',
      },
      {
        path: '/categories',
        name: 'Categories(product List)',
        mini: 'CA',
        component: Category,
        layout: '/dashboard-store',
      },
      {
        path: '/items',
        name: 'Items',
        mini: 'IT',
        component: Item,
        layout: '/dashboard-store',
      },
      {
        path: '/specifications',
        name: 'Specifications',
        mini: 'SP',
        component: Specifications,
        layout: '/dashboard-store',
      },
      {
        path: '/promo',
        name: 'Promo',
        mini: 'SP',
        component: Promo,
        layout: '/dashboard-store',
      },
    ],
  },
];
export const dashboardRoutes = [
  {
    collapse: true,
    name: 'Dashboard',
    icon: Dashboard,
    state: 'dashboardCollapse',
    views: [
      {
        path: '/dashboard/mv-order',
        name: 'MapView Current Orders',
        mini: 'MO',
        component: MapViewCurrentOrdersPage,
        layout: '/admin',
      },
      {
        path: '/dashboard/mv-delivery-man',
        name: 'MapView DeliveryMan',
        mini: 'MDM',
        component: MapViewDeliveryManPage,
        layout: '/admin',
      },
      {
        path: '/dashboard/mv-statistics',
        name: 'MapView Orders',
        mini: 'MVO',
        component: MapViewOrdersPage,
        layout: '/admin',
      },
      {
        path: '/dashboard/mv-stores',
        name: 'MapView Stores',
        mini: 'MVO',
        component: MapStoresPage,
        layout: '/admin',
      },
      // {
      //   path: '/dashboard/mv-delivery',
      //   name: 'MapView delivery',
      //   mini: 'MVO',
      //   component: deliveryManMapStoresPage,
      //   layout: '/admin',
      // },
    ],
  },
  {
    collapse: true,
    name: 'Delivery INFO',
    icon: LocalShippingIcon,
    state: 'deliveryInfoCollapse',
    views: [
      {
        path: '/delivery-info/service-categories',
        name: 'Service Categories',
        mini: 'SC',
        component: ServiceCategoriesPage,
        layout: '/admin',
      },
      {
        path: '/delivery-info/services',
        name: 'Services',
        mini: 'Se',
        component: ServicesPage,
        layout: '/admin',
      },
      {
        path: '/delivery-info/category-in-App-user',
        name: 'Category in App User',
        mini: 'CAU',
        component: CategoriesAppUserPage,
        layout: '/admin',
      },
      {
        path: '/delivery-info/countries',
        name: 'Countires',
        mini: 'Co',
        component: CountriesPage,
        layout: '/admin',
      },
      {
        path: '/delivery-info/cities',
        name: 'Cities',
        mini: 'Ci',
        component: CitiesPage,
        layout: '/admin',
      },
      {
        path: '/delivery-info/commission_config',
        name: 'Commission Config',
        mini: 'CO',
        component: CommissionConfigPage,
        layout: '/admin',
      },
      {
        path: '/delivery-info/choubik_commission_config',
        name: 'Choubik Commission Config',
        mini: 'CCO',
        component: ChoubikCommissionConfig,
        layout: '/admin',
      },
      {
        path: '/delivery-info/delivery-prices',
        name: 'Delivery Prices',
        mini: 'DP',
        component: DeliveryPricesPage,
        layout: '/admin',
      },
      {
        path: '/delivery-info/publicity',
        name: 'Publicity',
        mini: 'Pu',
        component: PublicityPage,
        layout: '/admin',
      },
      {
        path: '/delivery-info/create-publicity',
        name: 'create Publicity',
        mini: 'CPu',
        component: CreatePublicity,
        layout: '/admin',
      },
      {
        path: '/delivery-info/dispatching-modes',
        name: 'Dispatching Modes',
        mini: 'DM',
        component: DispatchingModesPage,
        layout: '/admin',
      },
      {
        path: '/delivery-info/city-service-mapping',
        name: 'City Service mapping',
        mini: 'CSM',
        component: CityServiceMappingPage,
        layout: '/admin',
      },
      {
        path: '/delivery-info/create-tag',
        name: 'Create tag',
        mini: 'T',
        component: CreateTagPage,
        layout: '/admin',
      },
      {
        path: '/delivery-info/tags',
        name: 'Tag',
        mini: 'TA',
        component: TagsPage,
        layout: '/admin',
      },
      {
        path: '/delivery-info/flags',
        name: 'Flag',
        mini: 'FL',
        component: FlagsPage,
        layout: '/admin',
      },
      {
        path: '/delivery-info/share',
        name: 'Parrinage',
        mini: 'PA',
        component: SharePage,
        layout: '/admin',
      },
      {
        path: '/delivery-info/Service_store',
        name: 'ServiceStore',
        mini: 'SA',
        component: ServiceStore,
        layout: '/admin',
        invisible: true,

      },
    ],
  },
  {
    collapse: true,
    name: 'Orders',
    icon: NotesIcon,
    state: 'ordersCollapse',
    views: [
      {
        path: '/orders/dispatching-service-view',
        name: 'Dispatching service View',
        mini: 'DSV',
        component: CurrentOrderPage,
        layout: '/admin',
      },
      {
        path: '/orders/current-orders',
        name: 'Current Orders',
        mini: 'CO',
        component: CurrentOrderPage2,
        layout: '/admin',
      },
      {
        path: '/orders/customer-service-view',
        name: 'Customer service View',
        mini: 'CSV',
        component: OrdersPage,
        layout: '/admin',
      },
      {
        path: '/orders/history',
        name: 'History',
        mini: 'HI',
        component: OrderHistoryPage,
        layout: '/admin',
      },
      {
        path: '/orders/rating',
        name: 'Rating',
        mini: 'OR',
        component: OrderRatingPage,
        layout: '/admin',
      },
      {
        path: '/orders/alarms',
        name: 'Alarms',
        mini: 'AL',
        component: OrderAlarmsPage,
        layout: '/admin',
      },
      {
        path: '/orders/export-exel',
        name: 'Export Exel',
        mini: 'EE',
        component: ExportExelPage,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Payments',
    icon: AccountBalanceIcon,
    state: 'paymentsCollapse',
    views: [
      {
        path: '/payments/customer-payments',
        name: 'Customer Payments',
        mini: 'CP',
        component: CustomerPaymentPage,
        layout: '/admin',
      },
      {
        path: '/payments/naps-payments',
        name: 'Naps Payments',
        mini: 'NP',
        component: NapsPaymentPage,
        layout: '/admin',
      },
      {
        path: '/payments/stores-payments',
        name: 'Stores Payments',
        mini: 'SP',
        component: PaymentPage,
        layout: '/admin',
      },
      {
        path: '/payments/delivery-man-payments',
        name: 'DeliveryMan Payments',
        mini: 'Pa',
        component: PaymentPage,
        layout: '/admin',
      },
      {
        path: '/payments/delivery-man-money-transfer',
        name: 'DeliveryMan Money Transfer',
        mini: 'MT',
        component: PaymentPage,
        layout: '/admin',
      },
      {
        path: '/payments/delivery-man-cash-return',
        name: 'DeliveryMan Cash Return',
        mini: 'CR',
        component: PaymentPage,
        layout: '/admin',
      },
      {
        path: '/payments/cards',
        name: 'Card',
        mini: 'CA',
        component: CardsPage,
        layout: '/admin',
      },
    ],
  },
  // {
  //   collapse: true,
  //   name: 'Delivery man',
  //   icon: SportsMotorsportsIcon,
  //   state: 'deliveryManCollapse',
  //   views: [
  //     {
  //       path: '/delivery-man/available',
  //       name: 'Available DeliveryMan',
  //       mini: 'Av',
  //       component: DeliveryManPage,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/delivery-man/approved',
  //       name: 'Approved DeliveryMan',
  //       mini: 'Ap',
  //       component: DeliveryManPage,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/delivery-man/unapproved',
  //       name: 'Unapproved DeliveryMan',
  //       mini: 'Un',
  //       component: DeliveryManPage,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/delivery-man/working-calendar',
  //       name: 'Working Calendars',
  //       mini: 'WC',
  //       component: DeliveryManPage,
  //       layout: '/admin',
  //     },
  //   ],
  // },
  {
    collapse: true,
    name: 'DeliveryMan',
    icon: SportsMotorsportsIcon,
    state: 'DeliveryManCollapse',
    views: [
      {
        path: '/approved-deliveryMen',
        name: 'Approved deliveryMen',
        mini: 'AD',
        component: ApprouvedProvider,
        layout: '/admin',
      },
      {
        path: '/unapproved-deliveryMen',
        name: 'Unapproved deliveryMen',
        mini: 'UD',
        component: UnapprouvedProvider,
        layout: '/admin',
      },
      {
        path: '/add-deliveryMen',
        name: 'Add deliveryMan',
        mini: 'AD',
        component: AddProvider,
        layout: '/admin',
      },
      {
        path: '/dispatchable-deliveryMan',
        name: 'Dispatchable DeliveryMan',
        mini: 'DD',
        component: ProviderDispatchingPage,
        layout: '/admin',
      },

      {
        path: '/DeliveryMan-balances-commission',
        name: 'DeliveryMan Balances/Commission',
        mini: 'DBC',
        component: ProviderBalance,
        layout: '/admin',
      },
      {
        path: '/balance-history',
        name: 'Balance History',
        mini: 'BA',
        component: BalancePage,
        layout: '/admin',
      },
      {
        path: '/commission-history',
        name: 'Commission History',
        mini: 'CH',
        component: CommissionHistoryPage,
        layout: '/admin',
      },
      {
        path: '/transfert-history ',
        name: 'Transfert History ',
        mini: 'TH',
        component: VirementProvider,
        layout: '/admin',
      },
      {
        path: '/validation-tips',
        name: 'Validation Tips ',
        mini: 'VT',
        component: ValidationTips,
        layout: '/admin',
      },
      {
        path: '/working-calendar-food',
        name: 'working calendar-food',
        mini: 'WCF',
        component: WorkingCalendarConfig,
        layout: '/admin',
      },
      {
        path: '/working-calendar-partner',
        name: 'working calendar-partner',
        mini: 'WCP',
        component: WorkingCalendarConfigPartner,
        layout: '/admin',
      },
      {
        path: '/provider_location',
        name: 'provider location',
        mini: 'PL',
        component: deliveryManLocationTabPage,
        layout: '/admin',
      },
      {
        path: '/provider_location_check_status',
        name: 'provider location status',
        mini: 'PL',
        component: deliveryManLocationStatus,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Customers',
    icon: GroupIcon,
    state: 'customersCollapse',
    views: [
      {
        path: '/customers/customers',
        name: 'Customers',
        mini: 'Cu',
        component: CustomersPage,
        layout: '/admin',
      },
      {
        path: '/customers/unapproved',
        name: 'Unapproved customers',
        mini: 'UC',
        component: UnapprovedCustomersPage,
        layout: '/admin',
      },
      {
        path: '/customers/kaalixPay-history',
        name: 'KaalixPay history',
        mini: 'KPH',
        component: kaalixPayHistoryPage,
        layout: '/admin',
      },
      {
        path: '/customers/kaalixUp-history',
        name: 'KaalixUp history',
        mini: 'KUH',
        component: kaalixUpHistoryPage,
        layout: '/admin',
      },
      {
        path: '/customers/code-promo',
        name: 'Code Promo',
        mini: 'CP',
        component: CodePromoPage,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Stores',
    icon: StoreIcon,
    state: 'storesCollapse',
    views: [
      {
        path: '/stores/stores-list',
        name: 'Stores list',
        mini: 'SL',
        component: StoresPage,
        layout: '/admin',
      },
      {
        path: '/stores/unapproved',
        name: 'Unapproved stores',
        mini: 'US',
        component: UnapprovedStoresPage,
        layout: '/admin',
      },
      {
        path: '/stores/services-store-mapping',
        name: 'Service stores mapping',
        mini: 'SSM',
        component: StoreMappingPage,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Deliveries',
    icon: GroupIcon,
    state: 'DeliveriesCollapse',
    views: [
      {
        path: '/partner/current-deliveries',
        name: 'deliveries',
        mini: 'DC',
        component: CurrentPartnerOrdersPage,
        layout: '/admin',
      },
      {
        path: '/partner/current-deliveries-v2',
        name: 'current deliveries',
        mini: 'CD',
        component: CurrentPartnerOrdersV2Page,
        layout: '/admin',
      },
      {
        path: '/partner/current-deliveries-v3',
        name: 'current deliveries v3',
        mini: 'CD',
        component: CurrentPartnerOrdersV3Page,
        layout: '/admin',
      },
      {
        path: '/partner/deliveries-history',
        name: 'deliveries history',
        mini: 'DH',
        component: OrderHistoryPartnerPage,
        layout: '/admin',
      },
      {
        path: '/partner/deliveries-terminated',
        name: 'deliveries terminated',
        mini: 'DH',
        component: OrderHistoryPartnerTerminated,
        layout: '/admin',
      },
      {
        path: '/partner/deliveries-pending',
        name: 'deliveries pending',
        mini: 'DH',
        component: OrderHistoryPartnerPending,
        layout: '/admin',
      },
      {
        path: '/partner/alarms',
        name: 'Alarms',
        mini: 'AL',
        component: DeliveriesAlarmsPage,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Partners',
    icon: GroupIcon,
    state: 'PartnersCollapse',
    views: [
      {
        path: '/partner/organisation-list',
        name: 'Organisation list',
        mini: 'OR',
        component: OrganisationPage,
        layout: '/admin',
      },
      {
        path: '/partner/all-deliveryMen-partner',
        name: 'all DeliveryMen partner',
        mini: 'DP',
        component: AllDeliveryMenPartnerPage,
        layout: '/admin',
      },
      {
        path: '/partner/deliveryMen-partner',
        name: 'DeliveryMen partner dispatchable',
        mini: 'DP',
        component: DeliveryMenPartnerPage,
        layout: '/admin',
      },
      {
        path: '/partner/deliveryMen-partner-tracking',
        name: 'DeliveryMen partner tracking',
        mini: 'DT',
        component: TrackingDeliveryManPage,
        layout: '/admin',
      },
      {
        path: '/partner/service-disponibilty',
        name: 'Service Disponibilty',
        mini: 'SD',
        component: ServicesDisponibilityPage,
        layout: '/admin',
      },
      {
        path: '/partner/call-api',
        name: 'Call Api',
        mini: 'CA',
        component: CallApiPage,
        layout: '/admin',
      },
      {
        path: '/partner/call-api-login',
        name: 'Call Api login',
        mini: 'CAL',
        component: CallApiForLoginPage,
        layout: '/admin',
      },

    ],
  },
  {
    collapse: true,
    name: 'All Partners',
    icon: GroupIcon,
    state: 'AllPartnersCollapse',
    views: [
      {
        path: '/AllPartners/organisation-list',
        name: 'Organisation list',
        mini: 'OR',
        component: OrganisationPageForAllParners,
        layout: '/admin',
      },
      {
        path: '/AllPartners/all-deliveryMen-partner',
        name: 'all DeliveryMen partner',
        mini: 'DP',
        component: AllDeliveryMenPartnerPageForAllParners,
        layout: '/admin',
      },
      {
        path: '/AllPartners/deliveryMen-partner',
        name: 'DeliveryMen partner dispatchable',
        mini: 'DP',
        component: DeliveryMenPartnerPageForAllParners,
        layout: '/admin',
      },

    ],
  },
  {
    collapse: true,
    name: 'Deliveries AllPartners',
    icon: GroupIcon,
    state: 'DeliveriesAllPartnersCollapse',
    views: [
      {
        path: '/AllPartners/current-deliveries',
        name: 'deliveries',
        mini: 'DC',
        component: CurrentAllPartnersOrdersPage,
        layout: '/admin',
      },
      {
        path: '/AllPartners/deliveries-history',
        name: 'deliveries history',
        mini: 'DH',
        component: OrderHistoryAllPartnersPage,
        layout: '/admin',
      },
      {
        path: '/AllPartners/resend-delivered',
        name: 'resend delivered',
        mini: 'RD',
        component: ResendDeliveredPage,
        layout: '/admin',
      },
      {
        path: '/AllPartners/alarms',
        name: 'Alarms',
        mini: 'AL',
        component: DeliveriesAlarmsAllPartnersPage,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Accompagnement',
    icon: StoreIcon,
    state: 'AccomCollapse',
    views: [
      {
        path: '/Accompagnement/stores-config',
        name: 'Stores config',
        mini: 'SC',
        component: StoreConfigPage,
        layout: '/admin',
      },

    ],
  },
  {
    collapse: true,
    name: 'Settings',
    icon: SettingsIcon,
    state: 'settingsCollapse',
    views: [
      {
        path: '/settings/admin-list',
        name: 'Admin list',
        mini: 'AL',
        component: AdminPage,
        layout: '/admin',
      },
      {
        path: '/settings/contact',
        name: 'Contact',
        mini: 'AL',
        component: ContactPage,
        layout: '/admin',
      },
      {
        path: '/settings/app',
        name: 'App settings',
        mini: 'AS',
        component: AppSettingPage,
        layout: '/admin',
      },
      // {
      //   path: '/settings/kaalix-up-config',
      //   name: 'kaalix Up Config',
      //   mini: 'AS',
      //   component: kaalixUpConfig,
      //   layout: '/admin',
      // },
    ],
  },
  {
    collapse: true,
    name: 'Audit',
    icon: AuditIcon,
    state: 'auditCollapse',
    views: [
      {
        path: '/audit',
        name: 'Log Audit',
        mini: 'LOG',
        component: AuditPage,
        layout: '/admin'
      }
    ]
  },
  {
    collapse: true,
    name: 'Non-quality',
    icon: QualityIcon,
    state: 'QualityCollapse',
    views: [
      {
        path: '/non-quality',
        name: 'Template Non Quality',
        mini: 'NQ',
        component: NonQualityPage,
        layout: '/admin'
      },
      {
        path: '/non-quality-customer',
        name: 'Non Quality Customer',
        mini: 'NQC',
        component: NonQualityCustomerPage,
        layout: '/admin'
      },
      {
        path: '/non-quality-delivery',
        name: 'Non Quality DeliveryMan',
        mini: 'NQD',
        component: NonQualityDeliveryPage,
        layout: '/admin'
      }
    ]
  },
  {
    collapse: true,
    name: 'driver',
    icon: QualityIcon,
    state: 'DriverCollapse',
    views: [
      {
        path: '/driver-current-ride',
        name: 'current Rides',
        mini: 'CD',
        component: currentRidePage,
        layout: '/admin'
      },
      {
        path: '/driver-invalid-ride',
        name: 'invalid Rides',
        mini: 'IR',
        component: invalidRidesPage,
        layout: '/admin'
      },
      {
        path: '/driver-ride-history',
        name: 'Rides History',
        mini: 'RH',
        component: RideHistoryPage,
        layout: '/admin'
      },
      {
        path: '/driver-dispatchable',
        name: 'driver dispatchable',
        mini: 'DD',
        component: Drivers,
        layout: '/admin'
      },
      {
        path: '/driver-planinfcations',
        name: 'driver planification',
        mini: 'DP',
        component: WorkingCalendarConfigDriver,
        layout: '/admin'
      },
      {
        path: '/driver-config',
        name: 'driver config',
        mini: 'DC',
        component: DriverConfigPage,
        layout: '/admin'
      },
      // {
      //   path: '/notCommited',
      //   name: 'nc',
      //   mini: 'DC',
      //   component: NotCommitedPage,
      //   layout: '/admin'
      // },

    ]
  },
  {
    collapse: true,
    name: 'livry',
    icon: QualityIcon,
    state: 'LivryCollapse',
    views: [
      {
        path: '/livry',
        name: 'livry',
        mini: 'LV',
        component: LivryOrganisationPage,
        layout: '/admin'
      },


    ]
  },
  // {
  //   collapse: true,
  //   name: 'Ecom Partners',
  //   icon: GroupIcon,
  //   state: 'EcomPartnersCollapse',
  //   views: [
  //     {
  //       path: '/ecom-partner/organisation-list',
  //       name: 'Ecom Partners list',
  //       mini: 'EL',
  //       component: OrganisationEcomPage,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/ecom-partner/all-deliveryMen-partner',
  //       name: 'all DeliveryMen Ecom partner',
  //       mini: 'DP',
  //       component: AllDeliveryMenEcomPartnerPage,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/ecom-partner/deliveryMen-partner',
  //       name: 'DeliveryMen Ecom partner dispatchable',
  //       mini: 'DP',
  //       component: DeliveryMenEcomPartnerPage,
  //       layout: '/admin',
  //     },

  //   ],
  // },
  // {
  //   collapse: true,
  //   name: 'Deliveries Ecom Partners',
  //   icon: GroupIcon,
  //   state: 'DeliveriesEcomPartnersCollapse',
  //   views: [
  //     {
  //       path: '/ecom-deliveries/current',
  //       name: 'Ecom deliveries',
  //       mini: 'ED',
  //       component: CurrentDeliveriesEcomPartnerPage,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/ecom-deliveries/history',
  //       name: 'Ecom deliveries History',
  //       mini: 'EDH',
  //       component: CompletedDeliveriesEcomPartnerPage,
  //       layout: '/admin',
  //     },

  //   ],
  // },

  // {
  //   collapse: true,
  //   name: 'Store nav',
  //   icon: SettingsIcon,
  //   state: 'settingsCollapse',
  //   views: [
  //     {
  //       path: '/store-nav/product-category-list',
  //       name: 'Product category list',
  //       mini: 'PCL',
  //       component: StoresPage,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/store-nav/product-list',
  //       name: 'Product list',
  //       mini: 'PL',
  //       component: StoresPage,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/store-nav/item-list',
  //       name: 'Item list',
  //       mini: 'IL',
  //       component: StoresPage,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/store-nav/specifications-list',
  //       name: 'Specifications list',
  //       mini: 'SL',
  //       component: StoresPage,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/store-nav/product-mapping-view',
  //       name: 'Products mapping view',
  //       mini: 'SL',
  //       component: StoresPage,
  //       layout: '/admin',
  //     },
  //   ],
  // }
];
const myDashboard = path.startsWith('/dashboard-store')
  ? dashStore
  : dashboardRoutes;

export default myDashboard;
