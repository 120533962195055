import React, { useEffect, useState } from 'react';
import {
  getDeliveryManTracking,
  socketConnectPertners,
} from '../services/server.api.service';
import DisplayPositionsMapForMonitoring from '../components/DisplayPositionsMapForMonitoring';
import ModalComponent from '../../../components/Modal.component';

export default function TrackingDeliveryManPage() {
  const [deliveryMen, setDeliveryMen] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [item, setSelectedItem] = useState(null);

  // ✅ Function to Fetch Data
  const fetchDeliveryMen = async () => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await getDeliveryManTracking();
      setDeliveryMen(data.deliveryMan);
      // setSelectedItem(data.deliveryMan[0]);
    } catch (err) {
      console.log(err.response);

      setError(err.response?.data?.message || err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDeliveryMen(); // Initial Fetch

    // ✅ Connect to Socket.IO
    let socket = socketConnectPertners();

    // ✅ Listen for real-time updates from `ADMIN_DISPATCHING`
    socket.emit('JOIN_ROOM', 'ADMIN_DISPATCHING'); // Join room
    socket.on('DELIVERYMAN_LOCATION_CHANGED', (updatedData) => {
      console.log('📡 Real-time update received:', updatedData);

      setDeliveryMen((prevData) =>
        prevData.filter(
          (man) => man.deliveryManId !== updatedData.deliveryManId,
        ),
      );
    });
    socket.on('DELIVERYMAN_INACTIVE', (newData) => {
      console.log('⚠️ DELIVERYMAN_INACTIVE received:', newData);

      setDeliveryMen((prevData) => {
        const index = prevData.findIndex(
          (man) => man?.deliveryManId === newData?.deliveryManId,
        );

        if (index !== -1) {
          const updatedList = [...prevData];
          updatedList[index] = { ...updatedList[index], ...newData };
          return updatedList;
        } else {
          return [...prevData, newData];
        }
      });
    });
    return () => {
      if (socket) {
        socket.disconnect();
        socket = null;
        console.log('🛑 Socket disconnected');
      }
    };
  }, []);
  const closeModelAction = () => {
    setSelectedItem(null);
  };
  const setDeliveryMan = (men) => {
    console.log('men', men);

    setSelectedItem(men);
  };
  return (
    <div className="container mx-auto p-6">
      {/* ✅ Header with Refresh Button */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Inactive Delivery Men</h2>
        <button
          onClick={fetchDeliveryMen}
          className="bg-blue-500  px-4 py-2 rounded-lg shadow hover:bg-blue-600 transition"
        >
          Refresh
        </button>
      </div>

      {loading && <p className="text-gray-500">Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}

      {!loading && !error && (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow">
            <thead>
              <tr className="bg-gray-100 text-left">
                <th className="py-2 px-4 border">Name</th>
                <th className="py-2 px-4 border">Phone</th>
                <th className="py-2 px-4 border">Location</th>
                <th className="py-2 px-4 border">Marjane IDs</th>
                <th className="py-2 px-4 border">Source</th>
                <th className="py-2 px-4 border">App Version</th>
                <th className="py-2 px-4 border">Last Position at</th>
              </tr>
            </thead>
            <tbody>
              {[...deliveryMen].map((man) => (
                <tr key={man._id} className="hover:bg-gray-50">
                  <td className="py-2 px-4 border">{man?.name}</td>
                  <td className="py-2 px-4 border">{man?.phone}</td>
                  <td
                    className="py-2 px-4 border"
                    onClick={() => setDeliveryMan(man)}
                  >
                    {man?.location[0] || ''}, {man?.location[1] || ''}
                  </td>
                  <td className="py-2 px-4 border">
                    {(man?.orders || []).length} (
                    {(man?.orders || []).map((x) => x?.orderData?.code + ' ')})
                  </td>
                  <td className="py-2 px-4 border">{man?.source}</td>
                  <td className="py-2 px-4 border">{man?.appVersion}</td>
                  <td className="py-2 px-4 border">
                    {new Date(man.timestamp).toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {item && (
        <ModalComponent
          maxWidth={'md'}
          close={() => closeModelAction()}
          visible={true}
          title="Delivery  Position "
        >
          <DisplayPositionsMapForMonitoring data={item} />
        </ModalComponent>
      )}
    </div>
  );
}
